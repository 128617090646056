import React from 'react';
import img1 from './Images/img-1.jpg';
import { Link, animateScroll as scroll } from "react-scroll";

// Background Styling
const Hero = () => {
    const style = {
        backgroundImage: `url('${img1}')`

    }

    return (
        <section id="Hero">
            {/* Hero Section */}
            <div className="bg-cover flex flex-col w-full h-screen items-center justify-center" style={style} id="Hero">
                <div className="bg-black/50 absolute w-full h-full pointer-events-none"></div>
                 {/*Title Text */}
                <h1 className="text font-semibold text-white text-center z-10 p-5 text-5xl md:text-6xl lg:text-8xl">MABUHAY SA E-AGRO</h1>


                <p className="text font-normal text-white text-center z-10 text-xl md:text-3xl lg:text-4xl">FARMER'S ASSISTANCE PROGRAM</p>
                  {/*CTA BUTTON */}
                <div className=' flex flex-col items-center justify-bottom z-10 mt-20 '>
                    <Link to="AboutUs" smooth={true} duration={500} className='focus:outline-none bg-green-600  hover:bg-green-900 text-white font-bold p-4 mb-3 rounded-lg text-2xl animate-bounce'>Learn More</Link>
                </div>
            </div>
        </section>



    )
};

export default Hero;
