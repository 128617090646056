import React from 'react';
import jogging from './Images/jogging.svg';
import login from './Images/login-image.svg';
import wallet from './Images/wallet-image.svg';
import approval from './Images/approval-image.svg';
import wish from './Images/wish-image.svg';
import { FcOvertime } from 'react-icons/fc';
import { FcMoneyTransfer } from 'react-icons/fc';
import { FcBusinessman } from 'react-icons/fc';
import { FcFlashOn } from 'react-icons/fc';

const Loan = () => {
    return (
        <section id="Loan">

            <section class="">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    {/* Right Side Image */}
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img src={jogging} className='' alt='image' ></img>
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 class="text-6xl  mb-4 font-medium text-gray-900">APPLY FOR A LOAN TODAY

                        </h1>
                        <p class="mb-8 text-xl leading-relaxed">Kunin ang mga pangangailangan ng mabilis, madali, at maginhawa sa inyong mga daliri.</p>
                        <div class="flex justify-center">
                            <a href='http://farmer.e-agro.ph/index.php' class="mt-4 mb-10 rounded-lg px-10 py-3 text-3xl bg-yellow-500 hover:bg-yellow-600 text-blue-50 ">
                                KUMUHA NG PAUTANG
                            </a>

                        </div>
                    </div>
                </div>
            </section>

            {/* Loan Intro Section */}

            {/* Gives info about the loans */}
            <div class="container px-6 py-8 mx-auto">
                <h1 class="text-center font-medium text-3xl md:text-4xl lg:text-6xl text-green-600">AMING PROGRAMMA</h1>

                <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6  ">


                    <div class="w-full p-8 space-y-8 text-center rounded-xl shadow-2xl">
                        <div className='flex flex-col items-center space-y-4'>
                            <FcOvertime size='5em' className='text-green-500 flex text-center' />
                            <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                            APRUBADO SA LOOB NG LIMANG MINUTO
                            </h2>
                        </div>

                    </div>

                    <div class="w-full p-8 space-y-8 text-center rounded-xl shadow-2xl">
                        <div className='flex flex-col items-center space-y-4'>
                            <FcMoneyTransfer size='5em' className='text-green-500 flex text-center' />

                            <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                            KAYANG UMABOT NANG HANGGANG PHP 100,000
                            </h2>
                        </div>
                    </div>

                    <div class="w-full p-8 space-y-8 text-center  rounded-xl shadow-2xl">
                        <div className='flex flex-col items-center space-y-4'>
                            <FcBusinessman size='5em' className='text-green-500 flex text-center' />

                            <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                            ISANG ID OR DOKUMENTO LANG ANG KAILANGAN
                            </h2>
                        </div>
                    </div>

                    <div class="w-full p-8 space-y-8 text-center  rounded-xl shadow-2xl">
                        <div className='flex flex-col items-center space-y-4'>
                            <FcFlashOn size='5em' className='text-green-500 flex text-center' />

                            <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                            MABILIS, MADALI, AT LIGTAS
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* How to apply for loan section */}
            <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                <h1 className=' text-green-600 pl-3 m-1 font-bold text text-5xl md:text-3xl text-center md:text-left lg:text-7xl'>How does it work?</h1>

                {/* Instruction Cards */}
                <h1 className=' text-green-600 pl-3 font-bold text-center text-xl md:text-2xl lg:text-4xl'>Step 1. Magpa-rehistro   </h1>
                <div className='bg-cover mt-10  bg-no-repeat bg-center'>
                    <div className='mt-4'>
                        <img src={approval} className='p-3' alt='image' ></img>
                    </div>

                </div>

                <h1 className=' text-green-600 mt-10 pl-3 font-bold text-center text-xl md:text-2xl lg:text-4xl'>Step 2. Magpa-apruba</h1>
                <div className='bg-cover mt-10  bg-no-repeat bg-center'>
                    <div className='mt-4'>
                        <img src={login} className='p-3' alt='image' ></img>
                    </div>

                </div>

                <h1 className=' text-green-600 mt-10 pl-3 font-bold text-center text-xl md:text-2xl lg:text-4xl'>Step 3. Tanggapin ang pondo</h1>
                <div className='bg-cover mt-10  bg-no-repeat bg-center'>
                    <div className='mt-4'>
                        <img src={wish} className='p-3' alt='image' ></img>
                    </div>

                </div>

                <h1 className=' text-green-600 mt-10 pl-3 font-bold text-center text-xl md:text-2xl lg:text-4xl'>Step 4. Pagbabalik-bayad</h1>
                <div className='bg-cover mt-10  bg-no-repeat bg-center'>
                    <div className='mt-4'>
                        <img src={wallet} className='p-3' alt='image' ></img>
                    </div>

                </div>
            </div>

            {/* Why grow with us? section */}
            <div className='flex flex-col w-full items-center mt-20 mb-20 contianer'>
                <h1 className='mt-5 text-green-600 font-bold text text-center mx-auto text-4xl md:text-6xl lg:text-8xl'>BAKIT MAGANDANG UMUNLAD KASAMA NAMIN?</h1>
                <div className='grid grid-cols-1 p-3 md:grid-cols-3 grid-rows-2 gap-4 mt-10 '>
                    {/* Cards */}
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">PAGTUTULAY TUNGO SA INYONG MAMIMILI</h3>
                            <p className=' text-center sm:text-lg md:text-xl'>
                            Matutulungan namin kayo na mailapit ang inyong mga produkto sa mga magsasaka na naka-lista sa aming database.
                            </p>

                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">SISTEMANG MADALING GAMITIN</h3>
                            <p className=' text-center sm:text-lg md:text-xl'>
                            Ang aming sistema ay madaling gamitin upang tulungan kayo ayusin ang inyong benta, imbentaryo, pagpapadala, at pag-iimbak sa bodega.
                            </p>

                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">MAY SEGURIDAD SA INYONG PANANALAPI</h3>
                            <p className=' text-center sm:text-lg md:text-xl'>
                            Direktang dinedeposito sa inyong bangko ang inyong salapi at ganito din sa Cash on Delivery na mga benta.
                            </p>

                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">MADALING PAG-IIMBENTARYO</h3>
                            <p className=' text-center sm:text-lg md:text-xl'>
                            Kami na ang bahala sa paghahawak ng imbentaryo, pagpapadala, at pagbabalik. Maaring masubaybayan ang inyong imbentaryo sa tamang oras.
                            </p>

                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">PATAKBUHIN ANG NEGOSYO KAHIT SAAN KA MAN</h3>
                            <p className=' text-center sm:text-lg md:text-xl'>
                            I-download ang aming E-AGRO app at patakbuhin ang iyong negosyo, tumugon sa inyong mamimili, at lutasin ang mga problema- kahit saan ka man at kahit kailan.
                            </p>

                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl mt-10 p-10'>
                        <div className='flex justify-center  text-green-600'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                        <div class="flex p-8 flex-col">
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mb-5">TULONG AT SUPORTA</h3>
                            <p className='text-center sm:text-lg md:text-xl'>
                            Ang E-Agro Contact Center Support ay laging nakahanda tulungan ka upang magbigay suporta at kasagutan kailanman at kahit saan.
                            </p>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Loan;
