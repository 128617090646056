import React, { useState } from 'react';
import { Data } from './Data';
import { Link } from 'react-router-dom';


const Research = () => {
    //click usestate
    const [clicked, setClicked] = useState(false)

    const toggle = index => {
        if (clicked == index) {
            //if clicked question is active, close it
            return setClicked(null)
        }

        setClicked(index)
    }
    //Page content
    return (
        <section id="Research">
            <div className='flex flex-col w-full items-center mt-20 mb-20 py-12'>

                <h1 className='mt-5 text-green-600 font-bold text-center text-5xl md:text-6xl lg:text-8xl'>FREQUENTLY ASKED QUESTIONS</h1>

                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">
                        <div class="flex flex-wrap -m-4">

                            {/* Card 1 */}
                            <div class="p-4 md:w-1/3">
                                <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                    <img class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://images.unsplash.com/photo-1513258496099-48168024aec0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="blog" />
                                    <div class="p-6">
                                        <a href="https://faq.onedoc.ph/?page_id=42" target="_blank" class="title-font text-2xl font-medium text-gray-900 mb-3">Knowledge Base</a>
                                        <p class="leading-relaxed mb-3 text-xl">
                                        Alamin ang mga pangangailangan patabang naaayon sa inyong lokasyon</p>
                            
                                    </div>
                                </div>
                            </div>



                            {/* Card 2 */}
                            <div class="p-4 md:w-1/3">
                                <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                    <img class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://images.pexels.com/photos/5732923/pexels-photo-5732923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="blog" />
                                    <div class="p-6">
                                        <a  href=" https://giscrop.onedoc.ph/bayambang_crop.html"  target="_blank" class="title-font text-2xl font-medium text-gray-900 mb-3">Mapa ng Pananim</a>
                                        <p class="leading-relaxed mb-3 text-xl">Alamin ang mga pananim na angkop sa inyong lokasyon</p>
                                       
                                    </div>
                                </div>
                            </div>

                            {/* Card 3 */}

                            <div class="p-4 md:w-1/3">
                                <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                    <img class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://images.pexels.com/photos/8703370/pexels-photo-8703370.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="blog" />
                                    <div class="p-6">
                                        <a href="https://giscrop.onedoc.ph/bayambang_ferreq.html" target="_blank" class="title-font text-2xl font-medium text-gray-900 mb-3" >Mapa Ukol sa Patabang Panlupa</a>
                                        <p class="leading-relaxed mb-3 text-xl">Alamin ang mga pangangailangan patabang naaayon sa inyong lokasyon</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </section>

    );

};

export default Research;
