import React from 'react'
import farmer4 from './Images/farmers-4.jpeg'
import { FcOvertime, FcMoneyTransfer, FcBusinessman, FcFlashOn } from 'react-icons/fc';
import { BiNetworkChart, BiFoodTag, BiWrench, BiCube, BiGroup } from "react-icons/bi";
import approval from './Images/approval-image.svg';



const Farmers = () => {
  return (
    <div>
      <section>
        {/* Headline Section */}
        <div class="px-4 py-12 mx-auto  2xl:max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24 2xl:px-12">
          <div class="flex flex-wrap items-center mx-auto 2xl:max-w-7xl">
            <div class="flex flex-col items-start mb-16 text-left  lg:flex-grow lg:w-1/2 lg:pr-24 md:mb-0">
              <span class="mb-8 text-lg font-bold tracking-widest text-yellow-600 uppercase">PARA SA MGA SUPPLIER </span>
              <h1 class="mb-8 text-4xl font-semibold leading-none tracking-tighter md:text-5xl lg:text-6xl"> PARA SA MGA SUPPLIER</h1>
              <p class="mb-8 text-lg leading-relaxed text-left"> Kunin ang mga pangangailangan ng mabilis, madali, at maginhawa sa pamamagitang ng inyong mga daliri.</p>
              <p class="mb-8 text-lg leading-relaxed text-left"> Sinusuportahan din namin ang mga supplier upang maitayo ng kanilang negosyo online at pamahalaan ito kahit saan at kahit kailan. Gamit ang aming logistic support services, mas inilalapit namin ang kanilang kalakal sa mga magsasaka.</p>
              <div class="mt-0 lg:mt-6 max-w-7xl sm:flex space-x-4">
                <div class="mt-3 rounded-lg sm:mt-0">
                  <a href='http://farmer.e-agro.ph/index.php ' class="items-center block px-6 py-3 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-yellow-500  rounded-xl hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"> LOGIN</a>
                </div>
                <div class="mt-3 rounded-lg sm:mt-0">
                  <a href='http://farmer.e-agro.ph/verify.php ' class="items-center block px-6 py-3  text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-green-500  rounded-xl hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"> REGISTER</a>
                </div>
                
              </div>
            </div>
            <div class="w-full mt-12 lg:w-5/6 lg:max-w-lg xl:mt-0">
              <div>
                {/* RIGHT SIDE IMAGE */}
                <div class="relative w-full max-w-lg">
                  <div class="relative">
                    <img class="object-cover object-center mx-auto shadow-2xl" alt="hero" src={farmer4}></img>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="container px-6 py-8 mx-auto mt-12">
          <h1 class="text-center font-semibold text-3xl md:text-4xl lg:text-6xl text-green-600 mb-8">BAKIT MAGANDANG MAGING PARTNER NAMIN?</h1>

          <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6  ">
            <div class="w-full p-8 space-y-8 text-center rounded-xl shadow-2xl">
              <div className='flex flex-col items-center space-y-4'>
                <FcOvertime size='5em' className='text-green-500 flex text-center' />
                <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                MAILALAPIT KAYO SA MGA INYONG MAMIMILI
                </h2>
              </div>

            </div>

            <div class="w-full p-8 space-y-8 text-center rounded-xl shadow-2xl">
              <div className='flex flex-col items-center space-y-4'>
                <FcMoneyTransfer size='5em' className='text-green-500 flex text-center' />
                <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                  Can loan up to PHP 100,000 per hectare
                </h2>
              </div>
            </div>

            <div class="w-full p-8 space-y-8 text-center  rounded-xl shadow-2xl">
              <div className='flex flex-col items-center space-y-4'>
                <FcBusinessman size='5em' className='text-green-500 flex text-center' />
                <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                  One ID or document is needed
                </h2>
              </div>
            </div>

            <div class="w-full p-8 space-y-8 text-center  rounded-xl shadow-2xl">
              <div className='flex flex-col items-center space-y-4'>
                <FcFlashOn size='5em' className='text-green-500 flex text-center' />

                <h2 class="text-2xl font-bold text-gray-800 uppercase ">
                  Quick, easy, and secure
                </h2>
              </div>
            </div>

          </div>

        </div>

        {/* PONDO SECTION */}
        <section class="">
          <div class="container px-5 py-24 mx-auto">

            <h1 class="text-6xl font-semibold t  text-green-600 mb-12">PAANO ITO MAGAGAWA</h1>
            <div class="flex flex-wrap -m-4 text-center items-stretch ">
              <div class="p-12 md:w-1/4 sm:w-1/2 w-full items-stretch">
                <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                  <BiNetworkChart className='w-12 h-12 mb-3 text-green-500 inline-block' />
                  <p class="leading-relaxed text-2xl">Magpa-rehistro</p>
                </div>
              </div>
              <div class="p-12 md:w-1/4 sm:w-1/2 w-full items-stretch">
                <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                  <BiFoodTag className='w-12 h-12 mb-3 text-green-500 inline-block' />
                  <p class="leading-relaxed text-2xl">Makipag-ugnayan sa mga mamimili</p>
                </div>
              </div>
              <div class="p-12 md:w-1/4 sm:w-1/2 w-full items-stretch">
                <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                  <BiCube className='w-12 h-12 mb-3 text-green-500 inline-block' />
                  <p class="leading-relaxed text-2xl">Ipadala ang iyong mga produkto</p>
                </div>
              </div>
              <div class="p-12 md:w-1/4 sm:w-1/2 w-full items-stretch">
                <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                  <BiWrench className='w-12 h-12 mb-3 text-green-500 inline-block' />

                  <p class="leading-relaxed text-2xl">Kitain ang nararapat</p>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* STEPS SECTION */}

        <section class="">
        <h1 class="text-6xl font-semibold t  text-green-600 mb-12">GET FUNDS FOR</h1>
          <div class="container px-5 py-24 mx-auto flex flex-wrap ">
            
            <div class="flex flex-wrap w-full justify-center">
              <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                <div class="flex relative pb-12">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                  </div>
                  <div class="flex-grow pl-8">
                    <h2 class="text-xl mb-1 tracking-wider">STEP 1 <b>Gumuwa ng E-Agro Supplier's Account</b></h2>
               
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                  </div>
                  <div class="flex-grow pl-8">
                    <h2 class="text-xl mb-1 tracking-wider">STEP 2 <b>Mag-upload ng wastong ID mula sa gobyerno</b></h2>
                  
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                  </div>
                  <div class="flex-grow pl-8">
                    <h2 class="text-xl mb-1 tracking-wider">STEP 3 <b> Basahin at pirmahan ang kasunduan</b></h2>
                    
                  </div>
                </div>
                <div class="flex relative pb-12">
                  <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                  </div>
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                  </div>
                  <div class="flex-grow pl-8">
                    <h2 class="text-xl mb-1 tracking-wider">STEP 4. <b> Ilagay ang mga kinakailangan na impormasyon ukol sa iyong bangko</b></h2>
                 
                  </div>
                </div>
                <div class="flex relative">
                  <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                  </div>
                  <div class="flex-grow pl-8">
                    <h2 class="text-xl mb-1 tracking-wider">STEP 5. <b> I-upload ang iyong mga produkto at simulan na ang pagbebenta!</b></h2>
                   
                  </div>
                </div>



              </div>
              <img src={approval} className='pl-12' alt='image' ></img>
            </div>
          </div>
        </section>

      </section>

    </div>
  )
}

export default Farmers