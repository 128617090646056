import React from 'react'

const LanguageToggle = () => {
    return (
        <div className='fixed  bottom-24 right-10 z-50'>

            <a href='http://english.e-agro.ph/' type="button" class=" inline-block p-3 bg-green-500 text-white font-bold text-md uppercase rounded-full shadow-md hover:bg-green-700  focus:bg-green-700   active:bg-green-800 ">
               English
            </a>

        </div>
    )
}

export default LanguageToggle