import React, { useState, useEffect } from 'react';
import { Link as Routerlink } from 'react-router-dom'
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ScrollToTop from 'react-scroll-to-top';
import Dropdown from './Components/Dropdown'


import Hero from './Components/Hero';
import AboutUs from './Components/AboutUs';
import OurProgram from './Components/OurProgram';
import Research from './Components/Research';
import Loan from './Components/Loan';
import LanguageToggle from './Components/LanguageToggle';

import Gov from './Components/Gov';
import MoreAboutUs from './Components/MoreAboutUs';
import Farmer from './Components/Farmers';
import Supplier from './Components/Suppliers';
import Contact from './Components/Contact';


import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';




function App() {

  return (
    <>
      <Router>
        <div className='App'>

          {/* Navbar Global Component */}
          <Navbar />
          <ScrollToTop smooth color="#EAB308" width="38"  />
          <LanguageToggle />
          

          <div className='content'>
            {/* Start of switch compoennt */}
            <Switch>
              <Route>
                {/* HOME PAGE ROUTE */}
                <Route exact path="/">

                  <Hero />
                  <AboutUs />

                </Route>

                {/* OURPROGRAM PAGE ROUTE */}
                <Route exact path="/OurProgram">
                  <OurProgram />

                </Route>

                {/* LOAN PAGE ROUTE */}
                <Route exact path="/Loan">
                  <Loan />

                </Route>

                {/* RESEARCH PAGE ROUTE */}
                <Route exact path="/Research">
                  <Research />

                </Route>

                {/* CONTACT PAGE ROUTE */}
                <Route exact path="/Contact">
                  <Contact />

                </Route>

                {/* GOV LINKS PAGE ROUTE */}
                <Route exact path="/Gov">
                  <Gov />

                </Route>

                {/* MOREABOUTUS PAGE ROUTE */}
                <Route exact path="/MoreAboutUs">
                  <MoreAboutUs />

                </Route>

                {/* FARMERS PAGE ROUTE */}
                <Route exact path="/Farmers">
                  <Farmer />

                </Route>

                {/* SUPPLIER PAGE ROUTE */}
                <Route exact path="/Supplier">
                  <Supplier />

                </Route>

              </Route>

            </Switch>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </Router>




    </>
  );
}

export default App;
