import React from 'react'
import farmer3 from './Images/farmers-3.jpg';

import reform from './Images/reform-1.jpg'
import Pres from './Images/pres.jpg';
import farmer5 from './Images/farmers-5.jpg'
const MoreAboutUs = () => {

  return (
    <div>
      <div className='container px-5 py-2 mx-auto flex flex-col'>
        {/* Banner Container */}
        <div children="lg:w-4/6 mx-auto">


          <div>
            <section class="">
              <div className='block md:flex w-full mt-12 mb-2 py-12'>
                <div className='flex flex-col items-center justify-center '>
                  <h1 className=' text-green-600 pl-3 font-semibold text text-2xl text-center md:text-left md:text-5xl lg:text-7xl'>MGA LAYUNIN</h1>


                </div>
                {/* Right Side Image */}
                <div className='flex-2 bg-cover bg-no-repeat bg-center'>
                  <img src={reform} className='' alt='image' ></img>
                </div>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 ">

                <ol class="mb-4 mt-4 text-lg leading-relaxed list-disc flex-inline">
                  {/* Make title text left-justify */}
                  <h1 className='mb-4 mt-4  text-3xl font-medium text-left'>TULONG SA MGA MAGSASAKA</h1>
                  <li className='m-2 p-2 text-md text-left'>Mag-pahiram sa mababang interest.</li>
                  <li className='m-2 p-2 text-md text-left'>Pagbili ng mga kagamitan sa mababang presyo</li>
                  <li className='m-2 p-2 text-md text-left'>Makabagong kaalaman tungkol sa pagsasaka</li>
                </ol>

                <ol class="mb-4 mt-4 text-lg leading-relaxed list-disc">
                  <h1 className='mb-4 mt-4  text-3xl font-medium text-left'>PAG-ANGAT MULA SA KAHIRAPAN</h1>
                  <li className='m-2 p-2 text-md text-left'>Palakihin ang kita ng mga magsasaka</li>
                  <li className='m-2 p-2 text-md text-left'>Bigyan ng mas maraming oportunidad</li>
                  <li className='m-2 p-2 text-md text-left'>Paunlarin ang ekonomiya</li>
                </ol>


              </div>
            </section>

          </div>
          <section class="mt-4">
            <h1 class="text-center font-medium text-4xl md:text-2xl lg:text-6xl py-12 text-green-600"> KASAYSAYAN NG
              E-AGRO CORPORATION
            </h1>
            <div class="container mx-auto flex px-5 md:flex-row flex-col items-center">

              <div class="flex flex-col text-right md:text-center">
                <div className='py-6 mb-6'>
                  <h1 class="text-center md:leading-relaxed sm:text-4xl text-3xl mb-4 font-medium text-gray-900 ">AMINMG INSPIRATION</h1>
                  <p class="mb-4 text-center md:leading-relaxed text-lg">Matagal nang napabayaan ang sektor ng agrikultura sa bansa. Marami ang naniniwala na walang pera sa agrikultura ngunit nais namin maiba.</p>
                  <p class="mb-4 text-center md:leading-relaxed text-lg">Ang pagsasaka ay maaaring maging kapaki-pakinabang sa pananalapi. Ang Bayambang, Pangasinan ay isang munisipalidad na umaasa sa agrikultura bilang pinagmumulan ng kabuhayan at pagpapa-unlad ng ekonomiya.</p>
                  <p class="mb-4 text-center md:leading-relaxed text-lg">Kahit ganoon, ang pinakamalaki hamon pa rin nila ay kahirapan. </p>
                </div>

                <p className='sm:text-4xl text-3xl mb-4 font-medium text-center text-gray-900'>Karaniwang hamon ng mga magsasaka </p>

                <div className='col-span-1 grid grid-cols-1 md:grid-cols-5 items-stretch md:flex-row  gap-2'>
                  {/* Cards */}
                  <div className='bg-white rounded-xl shadow-2xl mt-2 p-2'>
                    <div class="flex p-8 flex-col">
                      <p className='m-2 p-2 font-bold text-md text-center'>Maliit na kita at mataas na puhunan.</p>

                    </div>
                  </div>

                  <div className='bg-white rounded-xl shadow-2xl mt-2 p-2'>
                    <div class="flex p-8 flex-col">
                      <p className='m-2 p-2 font-bold text-md text-center'>Nakatali sa mga pa-utang na sobrang taas ng interest</p>


                    </div>
                  </div>

                  <div className='bg-white rounded-xl shadow-2xl mt-2 p-2'>
                    <div class="flex p-8 flex-col">
                      <p className='m-2 p-2 font-bold text-md text-center'>Pangangailangan ng kolateral para maka-utang</p>

                    </div>
                  </div>

                  <div className='bg-white rounded-xl shadow-2xl mt-2 p-2'>
                    <div class="flex p-8 flex-col">
                      <p className='m-2 p-2 font-bold text-md text-center'>Kahirapan ng pagbebenta ng ani</p>

                    </div>
                  </div>

                  <div className='bg-white rounded-xl shadow-2xl mt-2 p-2'>
                    <div class="flex p-8 flex-col">
                      <p className='m-2 p-2 font-bold text-md text-center'>Kulang sa kaalaman ng modernong pagsasaka.</p>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <div className='py-12'>
            <h1 class="text-center font-medium text-xl  text-green-600 mt-8">
              <strong className='text-3xl'>PHP 3,100 </strong>- Ang karaniwang buwanang kita ng isang Pilipinong magsasaka.
            </h1>

            <h1 class="text-center font-medium text-xl text-green-600 mt-8">
              <strong className='text-3xl'>30,850 FILIPINOS  </strong>ay miyembro ng mga mahihirap na pamilya sa Bayambang
            </h1>

            <h1 class="text-center font-medium text-xl  text-green-600 mt-8">
              <strong className='text-3xl'>66 BARANGAYS  </strong>BARANGAY ay nakaasa sa pagsasaka bilang kanilang kabuhayan.
            </h1>
          </div>

          <div className='py-6 '>
            <p class="mb-8 leading-relaxed text-xl">Bilang alkalde ng Bayambang, isang munisipalidad na pang-agrikultura, si Mayor Cezar T. Quiambao ay nagtatag ng mga programa upang mapabuti ang mga buhay ng mga magsasaka.</p>
            <p class="mb-8 leading-relaxed text-xl">Kaya noong ika-28 ng Agosto 2018, Araw ng mga Bayani, sinimulan ni Mayor Cezar T. Quiambao ang “Rebolusyon Laban sa Kahirapan” sa buong bayan para harapin at magbigay solusyon sa malaking hamon na ito.</p>
            <p class="mb-8 leading-relaxed text-xl">Ang kanyang nais na mapabuti ang mga magsasaka ay nagbigay daan sa “Bayambang Poverty Reduction Plan (BPRP) 2018-2028”.</p>
            <p class="mb-8 leading-relaxed text-xl">Isa sa mga limang sektor nito ay naka-focus sa “Agricultural Modernization.”  isang programang naglalayon na tulungan ang mga magsasaka magkaroon ng maganda buhay na kung saan naibibigay ang kanilang pangangailangan. </p>
          </div>

          <section class="">
            <div class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
              <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                <img src={Pres} class="object-cover object-center rounded" alt="hero"></img>
              </div>
              <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-end md:text-right items-center text-center">
                <h1 class="text-center md:text-right font-medium text-3xl  mb-4 md:text-4xl lg:text-6xl text-green-600">2021 GLOBAL MAYORS CHALLENGE</h1>
                <p class="mb-8 leading-relaxed text-xl">Nakilahok si Mayor Cezar T. Quiambao sa Bloomberg Philanthropies’ 2021 Global Mayor Challenge, kung saan hinihikayat nito ang mga lokal ng lider na ipakita ang kanilang mga pinaka-rebolusyonaryong ideya bilang sagot sa Covid-19.</p>
                <p class="mb-8 leading-relaxed text-xl">Bilang official entry, itinatag niya ang Farmers Assistance Portal- isang serbisyo pang-suporta para sa mga magsasaka na upang unahin ang kanilang mga pangangailangan. Nagpakita ito ng paglagong pang-pinansyal para sa mga magsasaka at sa mga tao sa industriya ng agrikultura.</p>
                <p class="mb-8 leading-relaxed text-xl">Hindi man ito napili ngunit nais pa rin ni Mayor Quiambao na ipagpatuloy upang mas lalo pang pagandahin ang buhay ng mga magsasaka at iba pang tao sa loob ng sektor ng agrikultura.</p>

              </div>
            </div>
          </section>

          <div className='block md:flex w-full mt-20 mb-20 py-12'>
            <div className='flex flex-col justify-center '>
              <h1 className=' title-font sm:text-5xl text-4xl mb-4 font-medium text-green-600 text-center md:text-left'>PAANO NAGING KAMI </h1>

              <p class="mb-8 leading-relaxed text-xl">Nakipagtulungan si Alkalde Cesar T. Quiambao sa mga negosyante mula sa pribadong sektor upang maitaguyod ang E-Agro Corporation  upang maisakatuparan ang mga programang agrikultura nang may sapat na pagkukunan.</p>
              <p class="mb-8 leading-relaxed text-xl">Ngayon, ang E-Agro Corporation ay nagsisilbing tulay sa mga magsasaka at supplier tungo sa mas maayos na supporta, pag-unlad, at katatagan.</p>

            </div>
            {/* Right Side Image */}
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img src={farmer5} class="object-cover object-center rounded" alt="hero"></img>
            </div>
          </div>

          <section class="">
            <div class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
              <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                <img src={farmer3} class="object-cover object-center rounded" alt="hero"></img>
              </div>
              <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-end md:text-right items-center text-center">
                <h1 class="text-center md:text-right  mb-4 font-medium text-3xl md:text-4xl lg:text-6xl text-green-600">AGRICULTURAL MODERNIZATION</h1>
                <p class="mb-8 leading-relaxed text-xl">Nakipagtulungan si Alkalde Cesar T. Quiambao sa mga negosyante mula sa pribadong sektor upang maitaguyod ang E-Agro Corporation upang maisakatuparan ang mga programang agrikultura nang may sapat na pagkukunan.</p>
                <p class="mb-8 leading-relaxed text-xl">Ngayon, ang E-Agro Corporation ay nagsisilbing tulay sa mga magsasaka at supplier tungo sa mas maayos na supporta, pag-unlad, at katatagan.</p>


              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  )
}

export default MoreAboutUs