import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link as Routerlink } from 'react-router-dom'
const Dropdown = () => {
    return (
        <Nav className="flex flex-col bg-yellow-500 text-white space-y-2 ">
            <Routerlink to='/' className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md '>HOME</Routerlink>
            <Routerlink to="/OurProgram" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md '>AMING MGA PROGRAMA</Routerlink>
            <Routerlink to="/Loan" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>PAG-UTANG</Routerlink>
            <Routerlink to="/Research" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>KAALAMAN</Routerlink>
            <Routerlink to="/Gov" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>USEFUL GOV LINKS</Routerlink>
            <Routerlink to="/Contact" className='mr-5 hover:text-green-600 hover:outline outline-3 outline-offset-2 cursor-pointer rounded-md'>CONTACT US</Routerlink>
        </Nav>
    )
}

export default Dropdown