import React from 'react';
import field from './Images/field-1.jpg';
import supply1 from './Images/supply-1.png';
import { Link } from 'react-router-dom';
import Logo from './Images/LOGO.png';

const Content = () => {

    return (
        <section id={'AboutUs'}>
            <div className='bg-cover p-3 mb-8 flex flex-col w-full items-center '>

                {/* Title */}
                <h1 className='mt-5 text-green-600 font-normal text  text-5xl md:text-6xl lg:text-8xl'>About Us</h1>

                {/* CARD Component */}
                <div class="bg-white rounded-xl shadow-2xl mt-10 p-10">
                    <div class="flex flex-col md:flex-row">
                        <img src={Logo} className='flex object-contain items-center h-48 w-96' alt='image' ></img>
                        <div class="flex p-8 flex-col">
                            {/* CARD Title */}
                            <h3 class="font-normal text-3xl md:text-4xl text-center text-green-700 mb-5">WHAT IS E-AGRO CORPORATION?</h3>
                            {/* CARD Text */}
                            <div className='space-y-4 flex flex-col items-center'>
                                <p className='max-w-md sm:text-lg md:text-lg text-center md:text-left'>Ang E-Agro Corporation ay naglalayon na tulungan ang mga magsasaka, mga supplier, at mga manggagawa sa pamamamagitan ng pagtulay sa kanila tungo sa sabayang pag-unlad at magandang kinabukasan.
                                  </p>
                                  <p className="max-w-md sm:text-lg md:text-lg text-center md:text-left">  Nagpapahiram kami ng puhunan na may mababang interes at walang kolateral, nagbibigay-tulong sa pagbili ng mga pangunahing pangangailangan ng mga magsasaka, at pag-renta ng makinarya at serbisyo sa mababang presyo.</p>

                            </div>
                            {/* CARD CTA */}
                            <Link to="/MoreAboutUs" className="mt-4 rounded-lg px-4 py-2 bg-yellow-500 text-white text-center text-2xl hover:bg-yellow-600">LEARN MORE</Link>

                        </div>
                    </div>
                </div>


                <div className='flex flex-col md:flex-row space-x-6'>
                    
                    {/* FARMER CARD Component */}
                    <div className='rounded-lg shadow-2xl mt-5 p-10'>
                        <div class="flex flex-col">
                            <img src={field} className='bg-auto bg-no-repeat bg-center h-56 rounded-md flex-shrink-0 ' alt='image' ></img>
                            {/* Title */}
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mt-2">For Farmers </h3>
                            <h3 class="font-bold text-lg sm:text-lg text-center text-green-700 mb-2 mt-2"> (MAGSASAKA) </h3>
                            {/* CARD Text */}
                            <div className='space-y-4 mb-4'>
                                <p className='max-w-md sm:text-lg md:text-xl'>Humiram ng puhunan upang mabili ang mga kagamitan, materyales, at makinarya sa murang presyo.</p>

                            </div>
                            <Link to="/Farmers" href='' class="bg-yellow-500 hover:bg-yellow-600 text-white p-2 mb-2 mt-2 rounded-lg md:p-18 text-2xl text-center">
                                LEARN MORE
                            </Link>

                        </div>
                    </div>

                    {/* SUPPLIER CARD Component */}
                    <div className='rounded-lg shadow-2xl mt-5 p-10'>
                        <div class="flex flex-col">
                            <img src={supply1} className='bg-auto bg-no-repeat bg-center h-56 rounded-md flex-shrink-0 ' alt='image' ></img>
                            {/* Title */}
                            <h3 class="font-bold md:text-2xl sm:text-lg text-center text-green-700 mt-2">For Suppliers</h3>
                            <h3 class="font-bold md:text-lg sm:text-lg text-center text-green-700 mb-2 mt-2">(MANGANGALAKAL)</h3>
                            {/* CARD Text */}
                            <div className='space-y-4 mb-4'>
                                <p className='max-w-md sm:text-lg md:text-xl'>Ilapit ang inyong kalakal sa mga magsasaka upang mapalago ang inyong negosyo.</p>

                            </div>
                            <Link to="/Supplier" href='' class="bg-yellow-500 text-white p-2 mb-2 mt-2 rounded-lg md:p-18 text-2xl text-center">
                                LEARN MORE
                            </Link>

                        </div>
                    </div>

                </div>


            </div>

        </section>

  
    );

};

export default Content;
