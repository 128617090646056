import React from 'react';
import {  Link as Routerlink } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaYoutube, } from "react-icons/fa";

const footer = () => {
    return (
        <div className='static inset-x-0 bottom-0'>
            {/* Footer Nav */}
            <div className='flex flex-col md:flex-row justify-center items-center p-2 text-2xl bg-green-900 text-white '>
                <Routerlink to='/' className='mr-5 hover:text-yellow-500 cursor-pointer  mt-8 '>HOME</Routerlink>
                <Routerlink to="/OurProgram" className='mr-5 hover:text-yellow-500  cursor-pointer  mt-8'>AMING MGA PROGRAMA</Routerlink>
                <Routerlink to="/Loan" className='mr-5 hover:text-yellow-500  cursor-pointer  mt-8'>PAG-UTANG</Routerlink>
                <Routerlink to="/Research" className='mr-5 hover:text-yellow-500 cursor-pointer  mt-8'>KAALAMAN</Routerlink>
                <Routerlink to="/Gov" className='mr-5 hover:text-yellow-500  cursor-pointer  mt-8'>USEFUL GOV LINKS</Routerlink>
                <Routerlink to="/Contact" className='mr-5 hover:text-yellow-500  cursor-pointer  mt-8'>CONTACT US</Routerlink>

            </div>
            {/* Social Media Icons */}
            <div className='flex flex-row justify-center items-center bg-green-900 text-white'>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaFacebookSquare size='2em' /></Routerlink>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaInstagram size='2em'  /></Routerlink>
                <Routerlink to="/" className="p-2 m-2 hover:text-yellow-500"><FaYoutube size='2em' /></Routerlink>
            </div>
            {/* Copyright Text */}
            <div className='flex flex-row justify-center items-center bg-green-900 text-white'>
                <p className='opacity-25'>©2022 E-AGRO. All Rights Reserved</p>
            </div>
        </div>
    );
};


export default footer;
