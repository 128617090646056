import supplier1 from './Images/supplier-1.jpg';
import React from 'react'
import farmer4 from './Images/farmers-4.jpeg';
import approval from './Images/approval-image.svg';
import { BiNetworkChart, BiFoodTag, BiWrench, BiCube, BiGroup } from "react-icons/bi";

const Suppliers = () => {
  return (
    <section>
      <div class="flex flex-wrap items-center mx-auto 2xl:max-w-7xl py-12">
        <div class="flex flex-col items-start mb-16 text-left  lg:flex-grow lg:w-1/2 lg:pr-24 md:mb-0">
          <span class="mb-8 text-lg font-bold tracking-widest text-yellow-600 uppercase">PARA SA MGA SUPPLIER</span>
          <h1 class="mb-8 text-4xl font-semibold leading-none tracking-tighter  md:text-7xl lg:text-5xl">PARA SA MGA SUPPLIER </h1>
          <p class="mb-8 text-base leading-relaxed text-left">Kunin ang mga pangangailangan ng mabilis, madali, at maginhawa sa pamamagitang ng inyong mga daliri. </p>
          <p class="mb-8 text-base leading-relaxed text-left">Sinusuportahan din namin ang mga supplier upang maitayo ng kanilang negosyo online at pamahalaan ito kahit saan at kahit kailan. Gamit ang aming logistic support services, mas inilalapit namin ang kanilang kalakal sa mga magsasaka.</p>
          <div class="mt-0 lg:mt-6 max-w-7xl sm:flex space-x-4">
                <div class="mt-3 rounded-lg sm:mt-0">
                  <a href='http://farmer.e-agro.ph/index.php ' class="items-center block px-6 py-3 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-yellow-500  rounded-xl hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"> LOGIN</a>
                </div>
                <div class="mt-3 rounded-lg sm:mt-0">
                  <a href='http://farmer.e-agro.ph/verify.php ' class="items-center block px-6 py-3  text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-green-500  rounded-xl hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"> REGISTER</a>
                </div>
                
              </div>
        </div>
        <div class="w-full mt-12 lg:w-5/6 lg:max-w-lg xl:mt-0">
          <div>
            <div class="relative w-full max-w-lg">
              <div class="relative">
                <img class="object-cover object-center mx-auto shadow-2xl" alt="hero" src={supplier1}></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-12'>

        <section class="text-gray-600 body-font">
          <div class="container px-5 py-24 mx-auto">
            <div class="text-center mb-20">
              <h1 class=" text-3xl md:text-5xl font-medium text-center title-font text-gray-900 mb-4">BAKIT MAGANDANG MAGING PARTNER NAMIN?</h1>

            </div>
            <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">MAILALAPIT KAYO SA MGA INYONG MAMIMILI</span>
                    <p className='text-sm text-center md:text-left'> 	Matutulungan namin kayo na mailapit ang inyong mga produkto sa mga magsasaka na naka-lista sa aming database</p>
                  </div>

                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">EASY-TO-USE SYSTEM</span>
                    <p className='text-sm text-center md:text-left'>Ang aming sistema ay madaling gamitin upang tulungan kayo ayusin ang inyong binebenta, imbentaryo, pagpapadala, at pag-iimbak sa bodega.</p>
                  </div>

                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">ANG INYONG PANANALAPI AY MAY SEGURIDAD</span>
                    <p className='text-sm text-center md:text-left'>I-download ang aming E-AGRO app at patakbuhin ang iyong negosyo, tumugon sa inyong mamimili, at lutasin ang mga problema- kahit saan ka man at kahit kailan. </p>
                  </div>

                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">TULONG AT SUPORTA</span>
                    <p className='text-sm text-center md:text-left'> Ang E-Agro Contact Center Support ay laging nakahanda tulungan ka upang magbigay suporta at kasagutan kailanman at kahit saan.</p>
                  </div>

                </div>
              </div>
              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">	MAG-NEGOSYO GAMIT ANG AMING APP</span>
                    <p className='text-sm text-center md:text-left'>I-download ang aming E-AGRO app at patakbuhin ang iyong negosyo, tumugon sa inyong mamimili, at lutasin ang mga problema- kahit saan ka man at kahit kailan.</p>
                  </div>

                </div>
              </div>

              <div class="p-2 sm:w-1/2 w-full">
                <div class="bg-gray-100 rounded flex p-10 h-full items-center">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <div className='flex flex-col'>
                    <span class="title-font font-medium text-center md:text-left">	TULONG AT SUPORTA</span>
                    <p className='text-sm text-center md:text-left'>Ang E-Agro Contact Center Support ay laging nakahanda tulungan ka upang magbigay suporta at kasagutan kailanman at kahit saan.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* PAANO SECTION */}
      <section class="">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="text-6xl font-semibold t mb-4 text-green-600">PAANO ITO MAGAGAWA</h1>

          </div>
          <div class="flex flex-wrap -m-4 text-center items-stretch items-">
            <div class="p-12 md:w-1/4 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <BiNetworkChart className='w-12 h-12 mb-3 text-green-500 inline-block' />
                <p class="leading-relaxed text-2xl">Magpa-rehistro</p>
              </div>
            </div>
            <div class="p-12 md:w-1/4 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <BiFoodTag className='w-12 h-12 mb-3 text-green-500 inline-block' />


                <p class="leading-relaxed text-2xl">Makipag-ugnayan sa mga mamimili</p>
              </div>
            </div>
            <div class="p-12 md:w-1/4 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <BiCube className='w-12 h-12 mb-3 text-green-500 inline-block' />
                <p class="leading-relaxed text-2xl">Ipadala ang iyong mga produkto</p>
              </div>
            </div>
            <div class="p-12 md:w-1/4 sm:w-1/2 w-full">
              <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                <BiWrench className='w-12 h-12 mb-3 text-green-500 inline-block' />

                <p class="leading-relaxed text-2xl">Kitain ang nararapat </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* STEPS SECTION */}

      <section class="">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">

          <div class="flex flex-wrap w-full justify-center">
            <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                </div>
                <div class="flex-grow pl-8">
                  <h2 class="text-xl mb-1 tracking-wider">STEP 1 <b>Gumuwa ng E-Agro Supplier's Account</b></h2>

                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                </div>
                <div class="flex-grow pl-8">
                  <h2 class="text-xl mb-1 tracking-wider">STEP 2 <b>Mag-upload ng wastong ID mula sa gobyerno</b></h2>

                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                </div>
                <div class="flex-grow pl-8">
                  <h2 class="text-xl mb-1 tracking-wider">STEP 3 <b>Basahin at pirmahan ang kasunduan</b></h2>

                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                </div>
                <div class="flex-grow pl-8">
                  <h2 class="text-xl mb-1 tracking-wider">STEP 4. <b>Ilagay ang mga kinakailangan na impormasyon ukol sa iyong bangko</b></h2>

                </div>
              </div>
              <div class="flex relative">
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">

                </div>
                <div class="flex-grow pl-8">
                  <h2 class="text-xl mb-1 tracking-wider">STEP 5. <b> I-upload ang iyong mga produkto at simulan na ang pagbebenta!</b></h2>

                </div>
              </div>



            </div>
            <img src={approval} className='pl-12' alt='image' ></img>
          </div>
        </div>
      </section>


    </section>

  )
}

export default Suppliers