import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-number-input';
import React, { useEffect, useState } from "react";

const Contact = () => {



    // emailjs funcitonality DO NOT REMOVE
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm("service_fidzr7w", "template_vbunzxt", e.target, 'C5FSBdHNKCDNSGboj').then(res => {
            console.log(res)
        })

        e.target.reset()
    }
    return (
        <section className=''>
            {/* Form */}
            <form className='row' onSubmit={sendEmail}>
                <div className="container px-5 py-24 mx-auto">
                    {/* Page title + container */}
                    <div className='flex flex-col text-center w-full mb-12"'>
                        <h1 class="text-5xl md:text-7xl font-medium title-font mb-4 text-green-600">Contact Us</h1>
                    </div>
                    {/* Input Container */}
                    <div class="lg:w-1/2 md:w-2/3 mx-auto">
                        <div class="flex flex-wrap -m-2">
                            {/* Name Input */}
                            <div class="p-2 w-1/2">
                                <div class="relative">
                                    <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                                    <input required type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            {/* Email Input */}
                            <div class="p-2 w-1/2">
                                <div class="relative">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                    <input required type="email" id="email" name="user_email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            {/* Phone Number Input */}
                            <div class="p-2 w-1/2">
                                <div class="relative">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Mobile Number</label>
                                    <input required type="tel" pattern='((^(\+)(\d){12}$)|(^\d{11}$))' id="number" name="phone_number" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            {/* Inquiry Type */}
                            <div class="p-2 w-1/2">
                                <div class="relative flex flex-col">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Inquiry Type</label>
                                    <select required type="text" name="inquiry_type" className='bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out py-2'>
                                        <option value="Tech Support">Tech Support</option>
                                        <option value="Product Inquiry">Product Inquiry</option>
                                        <option value="Loan Status">Loan Status</option>
                                    </select>

                                </div>
                            </div>
                            {/*  Email Message Button */}
                            <div class="p-2 w-full">
                                <div class="relative">
                                    <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                                    <textarea required id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                </div>
                            </div>
                            <div class="p-2 w-full">
                                <input className='bg-yellow-500 rounded-md px-4 py-2 text-white' type="submit" value="Submit" />
                            </div>
                            {/* Misc Info Container */}
                            <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                                <a class="text-green-500">info@e-agro.ph</a>
                                <p class="leading-normal my-5">Third Floor, Royal Mall, Rizal Avenue, Barangay Poblacion, Bayambang, Pangasinan
                                </p>

                            </div>
                        </div>
                    </div>


                </div>
            </form>

        </section>

    )
}
export default Contact;